<template>
  <div class="agrement_acontent">
    <p class="title">书营AI智能创作平台隐私政策</p>
    <div class="container_precondition">
      <div class="container_list">
        <div class="container_title">
          <li>提示条款</li>
        </div>
        <p>
          您的信任对我们非常重要，我们深知用户信息安全的重要性，我们将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。鉴此，书营AI智能创作平台（或简称“我们”）制定本《法律声明及隐私权政策》（以下或简称“声明和政策”）并提醒您：在使用书营AI智能创作平台网站的各项服务前，请您务必仔细阅读并透彻理解本《法律声明和隐私权政策》，在确认充分理解并同意后方使用相关产品和服务。一旦您开始使用书营AI智能创作平台服务，将被视为对本声明和政策内容的接受和认可，如果您不同意任何服务条款，则您有权停止访问平台和使用服务。
        </p>
      </div>
      <div class="container_list">
        <div class="container_title">
          <li>隐私权政策</li>
        </div>
        <p>
          书营AI智能创作平台（以下或称为“我们”）尊重并保护用户信息，并且将以高度勤勉和审慎的义务对待这些信息。为同时给您提供更准确、有个性化的服务和更安全的互联网环境，我们依据《中华人民共和国网络安全法》《中华人民共和国个人信息保护法》《中华人民共和国民法典》《信息安全技术个人信息安全规范》以及其他相关法律法规和技术规范明确了我们收集/使用/对外提供个人信息的原则，进一步阐述了关于您个人信息的相关权利。
        </p>
        <p>
          本政策与您所使用的我们的产品与/或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与/或服务（以下统称“使用我们的产品与/或服务”）时，我们将按照本政策的约定处理和保护您的个人信息。我们尽量以简明扼要的表述向您解释本政策所涉及的技术词汇，以便于您理解。
        </p>
        <p>
          因此我们建议您完整地阅读本隐私权政策，以帮助您了解维护自己隐私权的方式。并在需要时，按照本政策的指引，做出适当的选择。如果您不同意本政策的内容，将可能导致我们的产品与/或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用我们提供的产品与/或服务的行为，都表示您充分理解和同意本《书营AI用户隐私政策》（包括更新版本）的全部内容。
        </p>
      </div>
      <div class="container_list">
        <div class="container_title">
          <li>关于我们</li>
        </div>
        <p>
          书营AI智能创作平台及APP的经营者为浙江艺阁文化传媒集团有限公司（以下简称“艺阁集团”或“我们”）及其关联公司。
        </p>
      </div>
      <div class="container_list">
        <div class="container_title">
          <li>本政策将帮助您了解以下内容：</li>
        </div>
        <li>一、本协议的适用范围</li>
        <li>二、我们如何收集和使用您的用户信息</li>
        <li>三、我们如何共享、转让、公开披露您的用户信息</li>
        <li>四、用户业务数据和公开信息</li>
        <li>五、您如何管理您的用户信息</li>
        <li>六、我们如何使用 Cookie 和同类技术</li>
        <li>七、我们如何保护和保存您的用户信息</li>
        <li>八、免责声明</li>
        <li>九、隐私权政策的更新</li>
        <li>十、如何联系我们</li>
        <li>十一、未成年人保护</li>
      </div>
    </div>

    <div class="container_main">
      <p class="main_title">一、本协议的适用范围</p>
      <p class="main">
        1.1.本隐私权政策适用于书营AI智能创作平台所有服务。服务包括向您提供页面浏览、网站登录服务，以及通过书营AI智能创作平台向您提供的技术服务。
      </p>
      <p class="main">
        1.2.
        本隐私权政策不适用于其他第三方向您提供的服务。例如，书营AI智能创作平台API接口提供服务时，您提供的信息不适用本隐私权政策。
      </p>
      <p class="main_title">二、我们如何收集和使用您的用户信息</p>
      <p class="main">（一）我们收集您的用户信息的情形</p>
      <p class="main">您理解并同意：</p>
      <p class="main">
        书营AI写作星球是由艺阁集团开发的内容智能续写系统。用户输入关键词，自动生成高质量文章，适用于抖音、小红书、微信公众号、电商文案、百度、知乎、豆瓣等文案的写作，大大提高了创造者的写作效率，也为企业降低了大量人力成本。我们将在本政策中依次向您说明每项功能可能收集的个人信息范围、收集目的、收集方式，以及拒绝提供个人信息可能导致的结果。请您知悉，您拒绝提供各项功能实现所必要的对应信息时，仍然可以使用书营AI写作平台的其他功能。
      </p>
      <p class="main">
        我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用我们服务过程中主动提供或因使用我们产品和/或服务而产生的个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将已经收集的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
      </p>
      <p class="main">2.1.账号注册及登录</p>
      <p class="main">
        2.1.1.当您注册及登录时，如您使用一键登录的功能，基于我们与通信运营商的合作，我们会收集您的【手机号码和网络运营商信息】，以便为您提供快捷的登录服务；同时，为帮助您完成注册过程，我们还将收集您的【兴趣】，以为您提供书营AI智能创作平台初始服务
      </p>
      <p class="main">
        2.1.2.如您使用第三方账号进行登录，为了满足网络实名制要求，在您使用发布、评论及其他要求实名认证的功能与服务前，我们将另行收集您的手机号码以完成实名认证。请您谨慎考虑后提供这类信息，若您拒绝提供，您可能无法正常使用相应的功能。
      </p>
      <p class="main">
        2.1.3.
        您还可以根据自身需求选择填写或更改头像、名字、性别、生日、职业、地区、简介、信息来完善您的信息，但如您不提供此类信息，不会影响您使用本产品和相关服务。
      </p>
      <p class="main">
        2.1.4.
        如果您成功注册书营AI账号，我们会在书营AI应用程序及网站中显示您的名字、头像、生日、简介信息。
      </p>
      <p class="main">2.2.帮助您成为我们的会员</p>
      <p class="main">
        目前仅支持手机号登录，未来会支持其他登陆方式”，您可利用前述账号或书营AI写作平台认可的其他第三方账号（合称“第三方账号”）登录书营AI写作平台。我们会将依据与第三方的约定，在符合相关法律法规规定的前提下，使用您的这些用户信息。
      </p>
      <p class="main">2.3. 向您提供技术服务</p>
      <p class="main">
        2.3.1.
        根据中华人民共和国法律，在您使用具体的技术服务时，您应通过您的账号提供您的真实身份信息。您通过书营AI写作平台账号提供真实身份信息的，您有责任维护个人账号安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任。
      </p>
      <p class="main">
        2.3.2.您理解并承诺，您所设置的账号不得违反国家法律法规及平台的相关规则，
        不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚行为，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、
        名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册账号（包括但不限于频繁注册、批量注册账号等行为）。我们有权对您提交的信息进行审核。
      </p>
      <p class="main">
        2.3.3.如果您认为您的密码或者访问您账号的其他方式已经泄漏，丢失或被盗，或者认为某一未授权个人正在或可能试图通过您的账号登录平台或使用服务，
        请务必立即联系客服。
      </p>
      <p class="main">
        2.3.4.当您使用去水印和部分功能时，我们会请求您授权存储（相册、媒体和其他文件）、相机（摄像头）、麦克风（录音）权限。您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用书营AI智能创作的其他功能。
      </p>
      <p class="main">2.3.5.购买、查询、使用虚拟财产。</p>
      <p class="main">
        为了方便您查询虚拟财产，并尽量降低可能存在的风险，【钱包】会记录您的充值、余额与使用情况。
      </p>
      <p class="main">2.3.6.身份认证功能及账号找回。</p>
      <div class="main">
        <li>
          当您使用身份认证功能申请成为认证用户时，我们可能会收集您的身份信息，包括您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的个人身份。若您拒绝提供，不会影响书营AI智能创作平台其他功能的正常使用。
        </li>
        <li>
          当您使用账号找回功能时，我们可能会收集您的身份信息，包括您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的身份，便于您找回书营AI账号。若您拒绝提供，不会影响书营AI其他功能的正常使用。
        </li>
      </div>
      <p class="main">2.3.7.客服和售后服务功能。</p>
      <p class="main">
        当您联系我们的客服或提出我们的产品与/或服务的售后申请时，我们系统可能会记录您与客服之间的通讯记录。为了您的账号与系统安全，我们可能需要您提供相关个人信息与您之前提供的个人信息相匹配以验证您的用户身份。验证成功后，我们可能会收集您与我们的沟通信息（账号信息、订单信息、您为了证明相关事实而提供的图片/视频/文字信息及有关信息）、您的联系方式以及您与我们的沟通记录，包括线上沟通记录、电话录音，以便尽快为您解决问题和改进我们的产品与/或服务。同时，我们可能会以电话、短信、站内信及相关方式主动联系您，沟通您在使用我们产品及服务过程中产生的问题。
      </p>
      <p class="main">
        为了保证服务的体验、处理服务纠纷，您与客服间的通话信息可能会被录音。有关上述信息的收集和使用规则如下：
      </p>
      <div class="main">
        <li>
          当您使用小红书服务与平台发生纠纷时，我们可调取并使用录音信息作为解决纠纷的参考依据。
        </li>
        <li>
          为了向您提供客服和售后服务，我们可能将您的通话录音存储在第三方客服系统中，我们会通过协议约束等方式，确保第三方无法访问您的通话录音；除以下情形外，平台不会将录音提供给任何人（包括通话主、被叫方）：a.有权机关依法定程序调取；b.被录音的一方持司法机关出具的法律文件依法调取。
        </li>
      </div>
      <p class="main">
        2.3.8.支付功能。您可以在书营AI智能创作平台购买商品/服务以及会员服务。在您使用该功能的过程中可能会需要进行支付，在支付过程中，我们可能会收集您的第三方支付账号（支付宝账号、微信账号、银联账号及有关账号）。
      </p>
      <!-- <p class="main">
        2.3.9.为向您提供更便捷、更符合您个性化需求的商品信息展示、搜索及推送服务，我们会根据您的设备信息（包括2.2条所述设备信息及移动应用列表信息）和您使用书营AI智能创作平台客户端时的浏览使用信息、购买记录，提取您的偏好特征，并基于特征标签产出间接人群画像，用于向您展示、推送信息和可能的商业广告（请您理解，单独的设备信息无法识别特定自然人的身份信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化处理）。对于您在使用我们产品或服务的过程中提供的您的联系方式（电话、邮箱及其他联系方式），我们在运营中可能会以短信、电话的方式，为您提供您可能感兴趣的服务，功能或活动及相关商业性信息。我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或提供的其他方式进行退订或关闭，也可以直接与我们联系进行退订。
      </p> -->
      <p class="main">
        2.3.9．在您分享或被分享信息、参加活动情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以为您实现跳转、分享、活动联动功能或服务。
      </p>
      <p class="main">2.3.10.我们可能从第三方间接获取您的个人信息</p>
      <p class="main">
        如您使用第三方平台的账号登录时，经过您明确授权账号绑定后，第三方平台会向我们同步您在该平台上使用的头像、昵称、地区（如有）、性别（如有）及好友信息（针对微博平台），以使您可以直接登录并使用小红书。
      </p>
      <p class="main">2.4.征得授权同意的例外</p>
      <p class="main">
        根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：
      </p>
      <div class="main">
        <li>
          为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
        </li>
        <li>
          为履行法定职责或者法定义务所必需；.有权机关依法定程序调取；b.被录音的一方持司法机关出具的法律文件依法调取；
        </li>
        <li>
          为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
        </li>
        <li>
          为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
        </li>
        <li>
          依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
        </li>
        <li>法律法规规定的其他情形。</li>
      </div>
      <p class="main">（二）我们收集您的用户信息的情形</p>
      <p class="main">我们出于如下目的，使用您提交以及我们收集的用户信息：</p>
      <div class="main">
        <li>
          为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知；
        </li>
        <li>
          为了维护、改进服务，向您提供更符合您个性化需求的信息展示，我们可能将来自爱写作网站某项服务的用户信息与来自其他项服务的信息结合起来，做出特征模型并进行用户画像，向您展示、推送信息和可能的商业广告，包括但不限于关于爱写作产品的新闻以及市场活动及优惠促销信息、爱写作合作第三方的推广信息，或其他您可能感兴趣的内容。如果您不想接收我们给您发送的商业性电子信息，您可通过短信提示回复退订或根据信息中提供的退订方式予以退订；
        </li>
        <li>
          我们可能以用户信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息；
        </li>
        <li>
          为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或爱写作相关协议、规则的情况，我们可能使用您的会员信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施；
        </li>
        <li>
          如超出收集用户信息时所声称的目的，或者在超出具有直接或合理关联的范围使用用户信息前，我们会再次向您告知并征得您的明示同意。
        </li>
      </div>
      <p class="main_title">三、我们如何共享、转让、公开披露您的用户信息</p>
      <p class="main">3.1. 共享</p>
      <p class="main">
        我们不会与其他组织和个人共享您的用户信息，但以下情况除外：
      </p>
      <div class="main">
        <li>
          在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息；
        </li>
        <li>
          在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；
        </li>
        <li>
          为了促成交易或协助解决争议，某些情况下只有共享您的用户信息，才能促成交易或处理您与他人的纠纷或争议，例如，在爱写作云市场上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，书营AI智能创作平台有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成；
        </li>
      </div>
      <p class="main">
        您理解并知悉，为了向您提供更完善、优质的产品和服务，我们将授权商业合作伙伴为您提供部分服务。此种情形下，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。请您注意，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们将对信息数据的输出形式、流转、使用进行安全评估与处理，以保护数据安全。同时，我们会对合作伙伴进行严格的监督与管理，一旦发现其存在违规处理个人信息的行为，将立即停止合作并追究其法律责任。
      </p>
      <p class="main">3.2. 转让</p>
      <p class="main">
        转让是指将取得您个人信息的控制权转让给其他公司、组织或个人。除非获取您的明确同意，否则我们不会将您的个人信息转让给任何公司、组织或个人。但下述情形除外：
      </p>
      <div class="main">
        <li>事先已征得您的同意；</li>
        <li>您自行提出的；</li>
        <li>
          如果公司发生合并、分立、解散、被宣告破产，将可能涉及到个人信息转让，此种情况下我们会告知您接收方的名称或者姓名和联系方式并要求新的持有您个人信息的公司、组织继续受本政策的约束。若接收方变更个人信息处理目的、处理方式的，我们将要求其重新获取您的明示同意；
        </li>
        <li>其他法律法规规定的情形。</li>
      </div>
      <p class="main">3.3. 公开披露</p>
      <p class="main">
        公开披露是指向社会或不特定人群发布信息的行为。原则上，我们不会对您的个人信息进行公开披露。我们仅会在以下情况下，公开披露您的用户信息：
      </p>
      <div class="main">
        <li>因公布账号中奖、处罚通知时展示必要的相关脱敏信息；</li>
        <li>
          获得您明确同意或基于您的主动选择，我们可能会公开披露您的用户信息；
        </li>
        <li>
          或为保护书营AI智能创作平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或爱写作平台相关协议、规则披露关于您的用户信息。例如，若您作为云市场服务商销售假货或盗版商品，我们可能会公开披露您的店铺主体信息与处罚情况。
        </li>
      </div>
      <p class="main">
        3.4. 共享、转让、公开披露用户信息时事先征得授权同意的例外
      </p>
      <p class="main">
        根据相关法律法规的规定，在以下情形中，我们可能在未事先征得您的授权同意的情况下共享、转让、公开披露您的个人信息：
      </p>
      <div class="main">
        <li>
          为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
        </li>
        <li>为履行法定职责或者法定义务所必需；</li>
        <li>
          为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
        </li>
        <li>
          为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
        </li>
        <li>
          依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
        </li>
        <li>
          出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        </li>
        <li>您自行向社会公众公开的个人信息；</li>
        <li>法律法规规定的其他情形。</li>
      </div>
      <p class="main">
        请您了解，根据现行法律规定及监管要求，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，无需另行向您通知并征得您的同意。以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：
      </p>
      <p class="main_title">四、 用户业务数据和公开信息</p>
      <p class="main">
        不同于您的用户信息，对于用户业务数据和公开信息，书营AI智能创作平台将按如下方式处理：
      </p>
      <p class="main">4.1. 用户业务数据</p>
      <p class="main">
        4.1.1.
        您通过书营AI智能创作平台提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。书营AI智能创作平台作为人工智能融合整合服务平台，我们只会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。
      </p>
      <p class="main">
        4.1.2.
        数据来源来自互联网资源和算法训练结果，书营AI智能创作平台提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。
      </p>
      <p class="main">
        4.1.3.
        根据您与书营AI智能创作平台协商一致，书营AI智能创作平台恪守对用户的安全承诺，根据适用的法律保护用户存储在书营AI智能创作平台独立数据库进行管理维护。
      </p>
      <p class="main">4.2. 公开信息</p>
      <p class="main">
        4.2.1.
        公开信息是指您公开分享的任何信息，任何人都可以在使用和未使用爱写作网站服务期间查看或访问这些信息。例如您在书营AI智能创作平台开发者论坛发布的信息。
      </p>
      <p class="main_title">五、您如何管理您的用户信息</p>
      <p class="main">我们非常重视并尽全力保障您对自己个人信息的相关权利。</p>
      <p class="main">
        5.1. 您可以登录书营AI智能创作平台网站或客户端查看用户信息。
      </p>
      <p class="main">
        5.2.
        除上述外，有下列情形之一的，我们将应当主动删除您的个人信息，您也有权请求我们删除：
      </p>
      <div class="main">
        <li>处理目的已实现、无法实现或者为实现处理目的不再必要；</li>
        <li>我们已停止提供产品或者服务，或者保存期限已届满；</li>
        <li>您已明确撤回同意；</li>
        <li>
          如您有足够的理由认为我们违反法律、行政法规或者违反约定处理您的个人信息；
        </li>
        <li>如果我们收集、使用您的用户信息，却未征得您的明确同意；</li>
        <li>如果我们处理个人信息的行为严重违反了与您的约定。</li>
      </div>
      <p class="main">
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
      </p>
      <p class="main">
        5.3.
        您可以通过以下方式申请注销您的账户：您可自行在后台反馈中，提出注销申请即可。
      </p>
      <p class="main_title">六、我们如何使用 Cookie 和同类技术</p>
      <p class="main">
        6.1.
        为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为
        Cookie 的小数据文件。Cookie
        通常包含标识符、站点名称以及一些号码和字符。爱写作只能读取书营AI智能创作平台提供的
        Cookies 。
      </p>
      <p class="main">
        6.2. 借助于 Cookie
        ，能够存储您的偏好或购买清单中的商品等数据。当下一次您再次访问的时候，我们将显示您需要的信息；或由书营AI智能创作平台通过
        Cookies
        文件来辨识您的来源网站，以便书营AI智能创作平台能追踪爱写作的广告效果。
      </p>
      <p class="main">
        6.3. 您可根据自己的偏好管理 Cookie ，您也可以清除计算机上保存的所有
        Cookie 。大部分网络浏览器都设有阻止 Cookie
        的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
      </p>
      <p class="main">
        6.4. 除 Cookie
        外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
      </p>
      <p class="main_title">七、我们如何保护和保存您的用户信息</p>
      <p class="main">
        7.1.
        书营AI智能创作平台非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息。防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们对书营AI智能创作平台网站提供
        HTTPS
        协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
      </p>
      <p class="main">
        7.2.
        我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非受到法律的允许。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。
      </p>
      <p class="main">
        7.3.
        请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </p>
      <p class="main">
        7.4.
        在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。
      </p>
      <p class="main">
        7.5. 同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
      </p>
      <p class="main">
        7.6.
        我们将收集到的您的用户信息存放在中华人民共和国境内，如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，并向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况。
      </p>
      <p class="main">
        7.7.
        如出现书营AI智能创作平台产品和服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。
      </p>
      <p class="main_title">八、免责声明</p>
      <p class="main">
        8.1
        书营AI智能创作平台仅为用户创作提供脉络梳理、素材搜集、审核校对等功能，内容产出依旧由用户主导，最终成文结果也由用户自行把控，书营AI智能创作平台不对用户发表、转载的内容提供任何形式的保证。因网络状况、通讯线路、第三方网站或管理部门的要求等任何原因而导致您不能正常使用爱写作，书营AI智能创作平台不承担任何法律责任。
      </p>
      <p class="main">
        8.2
        用户创作的内容，仅代表其个人的立场，并不代表书营AI智能创作平台的立场。书营AI智能创作平台仅为用户内容创作提供参考，作为内容的发表者，需自行对所发表的内容负责。因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。书营AI智能创作平台不对用户的观点和真实性负责，也不构成任何其他建议，不承担任何法律及连带责任。
      </p>
      <p class="main">
        8.3
        书营AI智能创作平台提供的参考内容均来自互联网，本公司不拥有著作权，仅供用户参考。用户利用本服务可能侵犯第三方著作权或其他知识产权的，用户应遵守相关法律法规，取得合法授权或许可；若权利人对于用户使用本服务产生的任何内容享有合法权利的，应及时通知爱写作并提交合法权利声明，书营AI智能创作平台将依据相关法律法规采取移除、删除或屏蔽等处理措施。用户在书营AI智能创作平台创作的内容，若引用其他人的内容，需尊重原作者或素材提供者的版权权力，我们支持原创且不赞同任何抄袭行为。用户从第三方导入至本平台的内容依旧归原作者所有。如果用户在创作的内容中引用的素材涉嫌侵权行为，请用户与原作者协商联系。如果有任何侵权行为，原作者有权要求用户删除侵害内容。如果涉及侵权，权益受害者可向本平台客服举报申诉，本平台有权对内容进行删除。
      </p>
      <p class="main">
        8.4
        书营AI智能创作平台对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害可能来自：不正当使用网络服务，非法使用网络服务或用户传送的信息有所变动。这些行为都有可能会导致本站的形象受损，所以本站事先提出这种损害的可能性。
      </p>
      <p class="main">
        8.5
        如因不可抗力或其他本站无法控制的原因（包括但不限于计算机病毒、黑客攻击、第三方恶意行为等）使本站系统崩溃或无法正常使用导致丢失有关的信息、记录等，书营AI智能创作平台不承担责任。但是书营AI智能创作平台会尽可能合理地协助处理善后事宜，并努力使客户免受损失。除了本站的使用条件中规定的其它限制和除外情况之外，在中国法律法规所允许的限度内，对于因使用本站服务而引起的或与之有关的任何直接的、间接的、特殊的、附带的、后果性的或惩罚性的损害，或任何其它性质的损害，本站在任何情况下都不承担责任。
      </p>
      <p class="main_title">九、隐私权政策的更新</p>
      <p class="main">9.1. 我们的隐私权政策可能修订。</p>
      <p class="main">
        9.2.
        未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。我们会在专门页面上发布对隐私权政策所做的任何修订。
      </p>
      <p class="main">
        9.3.
        对于重大修订，我们还会提供更为显著的通知（包括对于某些服务，我们会通过网站公示的方式进行通知甚至向您提供弹窗提示，说明隐私权政策的具体变更内容）。
      </p>
      <p class="main">9.4. 本政策所指的重大变更包括但不限于：</p>
      <p class="main">
        9.4.1.
        我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
      </p>
      <p class="main">
        9.4.2.
        我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p class="main">9.4.3.用户信息共享、转让或公开披露的主要对象发生变化；</p>
      <p class="main">
        9.4.4. 您参与用户信息处理方面的权利及其行使方式发生重大变化；
      </p>
      <p class="main_title">十、如何联系我们</p>
      <p class="main">
        您对本声明和政策内容有任何疑问和意见，或者您对
        书营AI智能创作平台对本隐私权政策的实践以及操作上有任何疑问和意见，请通过在线客服与我们联系。
      </p>
      <p class="main">
        我们将尽快审核所涉问题，并在验证您的用户身份后的三十天内予以回复。
      </p>
      <p class="main_title">十一、未成年人保护</p>
      <p class="main">
        以下条款请未成年用户（特别是未满十四周岁的儿童用户）在监护人的陪同下仔细阅读，并由监护人在充分理解后作出是否接受或拒绝本政策的决定：
      </p>
      <p class="main">
        11.1.若用户是未满18周岁的未成年人（特别是未满十四周岁的儿童用户），应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用书营AI智能创作平台相关服务。
      </p>
      <p class="main">
        11.2.我们重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用书营AI智能创作平台相关服务。
      </p>
      <p class="main">
        11.3.我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您根据本政策第十条提供的联系方式及时与我们联系。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
p {
  font-size: 0.875rem;
}
li {
  font-size: 0.875rem;
}
.agrement_acontent {
  max-width: 49.375rem;
  margin: 0 auto;
  box-shadow: 0 0 .6875rem 0 rgba(63, 95, 150, 0.04);
  box-sizing: border-box;
  padding: 1.25rem;
  .title {
    width: 100%;
    height: 7.5rem;
    box-sizing: border-box;
    padding: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    text-align: center;
    font-size: 2.125rem;
    font-weight: 900;
    color: #1f2329;
    border-bottom: 2px #1f2329 solid;
  }

  .container_precondition {
    .container_list {
      margin: 1.25rem 0 0 0;
      .container_title {
        padding: 0;
        width: auto;
        font-size: 1rem;
        text-align: left;
        border: none;
        font-weight: 600;
        li {
          font-size: 1rem;
          list-style: disc;
        }
        // margin: 0 0 1.25rem 0;
      }
      p {
        text-align: left;
        text-indent: 1rem;
        margin: 0 0 1.25rem 0;
        line-height: 1.25rem;
      }
      li {
        height: 1.25rem;
        text-align: left;
      }
      li:last-child {
        padding: 0 0 1.25rem 0;
      }
    }
    .container_list:last-child {
      margin: 0 0 2.5rem 0;
      border-bottom: 2px solid #1f2329;
    }
  }

  .container_main {
    .main_title {
      font-size: 1rem;
      font-weight: 700;
      text-align: left;
      margin: 0 0 1.25rem 0;
    }
    // .main_title_h2 {
    //   text-align: left;
    //   font-weight: 600;
    //   margin: 0 0 1.25rem 0;
    // }
    .main {
      margin: 0 0 1.25rem 0;
      text-align: left;
      line-height: 1.25rem;
      li {
        list-style: square;
      }
      li:last-child {
        margin: 0 0 1.25rem;
      }
    }
  }
}
</style>
